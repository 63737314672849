import React, { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Pagination, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import useRealtimeDatabase from '../../hooks/useRealtimeDatabase';
import { useLoader } from '../../context/LoaderContext';
import { updateUserData, deleteUser } from '../../service/firebaseService';
import { useNotification } from '../../context/NotificationContext';
import { useAuth } from '../../context/AuthContext';

interface User {
  id: string;
  name: string;
  role: string;
  email: string;
  isAdmin:boolean;
  isActive: boolean;
}

const UserListPage: React.FC = () => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedUserName, setSelectedUserName] = useState<string | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10); // Number of users to display per page
  const { data, loading, error } = useRealtimeDatabase("Users");
  const { showLoader, hideLoader, isLoading } = useLoader();
  const { showNotification } = useNotification();
  const {currentUser} = useAuth();

  useEffect(() => {
    if (loading || (data == null && error == null)) {
      showLoader();
    } else {
      hideLoader();
    }
    if (data != null) {
      const userArray = Object.keys(data).map((userId) => ({
        id: userId,
        ...data[userId]
      }));
      setUsers(userArray);
    }
  }, [data, loading, error]);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const handleUserClick = (user: any) => {
    setSelectedUserId(user.id);
    setOpenDeleteModal(true);
  };

  const handleDeleteUser = (userId: string, userName: string) => {
    setSelectedUserId(userId);
    setSelectedUserName(userName);
    setOpenDeleteModal(true);
  };

  const handleApproveUser = (userId: string, userName: string) => {
    setSelectedUserId(userId);
    setSelectedUserName(userName);
    setOpenApproveModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCloseApproveModal = () => {
    setOpenApproveModal(false);
  };

  const handleConfirmDelete = (id:string| null) => {
    if (id) {
      deleteUser(id);
      // Approve user logic goes here
      setOpenApproveModal(false);
      showNotification('User deleted successfully.', 'success', 5000);
    } 
    return 

  };

  const handleConfirmApprove = (id:string| null) => {
    if (id) {
      updateUserData(id, {isActive:true});
      // Approve user logic goes here
      setOpenApproveModal(false);
      showNotification('User approved successfully.', 'success', 5000);
    } 
    return 

  };

  return (
    <div style={{ margin:"5px",border: "1px solid #ccc", borderRadius: "10px" }}>
      <Grid   spacing={2}>
      <Typography variant="h5"  sx={{marginLeft:"15px", marginTop:"15px"}} >Users Listing</Typography> 
        <div style={{ overflowY: 'auto', maxHeight:"600px" }}> {/* Fixed height with scrollbar */}
          <TableContainer component={Paper} elevation={3} sx={{ margin:"10px", maxWidth:"98%" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
            {/* </Table> */}
            <TableBody>
              {!isLoading && currentUsers.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3}>No users found.</TableCell>
                </TableRow>
              ) : (
                currentUsers.map((user: User) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.isAdmin == true ? "Admin" : "User"}</TableCell>
                    <TableCell>
                      {user.isActive == false && <IconButton aria-label="approve" onClick={() => handleApproveUser(user.id, user.name)}>
                        <CheckIcon />
                      </IconButton>}
                      <IconButton aria-label="delete" onClick={() => handleDeleteUser(user.id, user.name)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        </div>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
        <Pagination
          count={Math.ceil(users.length / usersPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          

          color="primary"
        />
      </div>
      {/* Delete confirmation dialog */}
      <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the user with name: {selectedUserName}?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="primary">
            No
          </Button>
          <Button onClick={() => handleConfirmDelete(selectedUserId)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* Approve confirmation dialog */}
      <Dialog open={openApproveModal} onClose={handleCloseApproveModal}>
        <DialogTitle>Confirm Approve</DialogTitle>
        <DialogContent>
          Are you sure you want to approve the user with name: {selectedUserName}?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseApproveModal} color="primary">
            No
          </Button>
          <Button onClick={() => handleConfirmApprove(selectedUserId)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserListPage;
