import React, { useState, useEffect } from 'react';
import { Modal, Typography, Paper, Button, Pagination, Grid, Box } from '@mui/material';
import useRealtimeDatabase from '../../hooks/useRealtimeDatabase';
import { useLoader } from '../../context/LoaderContext';
import EventModal from './EventModal'; // Import the EventModal component
import { formatDate, formatTime } from '../../common/functions';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// EventList component
const EventList: React.FC = () => {
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [events, setEvents] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage] = useState(12);
  const { data, loading, error } = useRealtimeDatabase("Events");
  const { showLoader, hideLoader, isLoading } = useLoader();

  useEffect(() => {
    if (loading || (data == null && error == null)) {
      showLoader();
    } else {
      hideLoader()
    }
    if (data != null) {
      const today = new Date();
      let eventArray = Object.keys(data).map((eventId) => ({
        id: eventId,
        ...data[eventId]
      }));
      eventArray = eventArray.filter((eventItem) => {
        // Check if the event has a valid date
        const eventDate = eventItem.date ? new Date(eventItem.date) : null;
        if (!(eventDate instanceof Date) || isNaN(eventDate.getTime())) {
          return false; // Skip events with invalid dates
        }
        

        const eventEnd = eventItem.dateEnd ? new Date(eventItem.dateEnd) : null;
        if (!(eventEnd instanceof Date) || isNaN(eventEnd.getTime())) {
          return false; // Skip events with invalid dates
        }
        
        // Check if the event has a valid time
        const eventTime = eventDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        if (eventTime === 'Invalid Date') {
          return false; // Skip events with invalid times
        }
    
        // Check if the event is not cancelled and is greater than today's date
        return (
          eventItem.status !== 'cancelled' &&
          (eventDate > today || eventEnd >= today)
        );
      })
      .sort((firstItem, secondItem) => {
        const firstDue = firstItem.date ? new Date(firstItem.date).getTime() : null;
        const secondDue = secondItem.date ? new Date(secondItem.date).getTime() : null;
      
        // If either due date is missing, leave the order unchanged
        if (!firstDue || !secondDue) {
          return 0;
        }
      
        // Compare due dates
        return firstDue - secondDue;
      });
      setEvents(eventArray);
    }
    
  }, [data, loading, error]);

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const handleEventClick = (event: any) => {
    setSelectedEvent(event);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div style={{ margin:"5px",border: "1px solid #ccc", borderRadius: "10px" }}>
      <Typography variant="h5" gutterBottom sx={{ ml:2, mt:2 }}>Events</Typography>
      <Grid container sx={{ marginLeft:"10px",marginRight:"10px", maxWidth:"98%" }}>
      {!isLoading && currentEvents.length === 0 ? (
        <Typography variant="body1">No events found.</Typography>
      ) : (
        currentEvents.map((event: any) => (
          <Grid item xs={12} sm={6} md={4} key={event.id} sx={{ width: 'calc(100% - 2%)', maxWidth:"98%" }}>
            {/* <Paper elevation={3} onClick={() => handleEventClick(event)} sx={{ p: 2, cursor: 'pointer', height: '100%' }}> */}
            <Paper elevation={3}  sx={{ p: 2, cursor: 'pointer', height: '100%' }}>
              <Typography variant="h6" sx={{ mb: 1 }}>{event.summary}</Typography>
              
              <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  {formatDate(event.date, "1")} {formatTime(event.date)}
                </Typography>
                {/* <KeyboardArrowRightIcon /> */}
              </Box>
              <Typography variant="body2" sx={{ mb: 1 }}>{event.description}</Typography>
            </Paper>
          </Grid>
        ))
      )}
      </Grid>
      <Box  sx={{marginTop:"4rem", marginBottom:"2rem"}} display="flex" justifyContent="center">
        <Pagination
          count={Math.ceil(events.length / eventsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
      {/* Modal to display event details */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <EventModal  onClose={handleCloseModal} event={selectedEvent} />
      </Modal>
    </div>
  );
};

export default EventList;
