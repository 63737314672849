import React from 'react';
import { Modal, Typography, Paper, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { formatDate, formatTime } from '../../common/functions';

interface EventModalProps {
  event: any; // Define the type of the event object
  onClose?: () => void; // Make onClose function optional
}

const EventModal: React.FC<EventModalProps> = ({ event, onClose }) => {
  return (
    <Modal open={true} onClose={onClose}>
      <Paper 
        style={{ 
          position: 'absolute', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          padding: '20px', 
          minWidth: '300px', 
          maxWidth: '80%', 
          outline: 'none',
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)' 
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', marginLeft:'4rem' }}>
            <CalendarMonthIcon sx={{ fontSize: 40 }} />
          </Box>
          <IconButton onClick={onClose} color="primary">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6" id="modal-modal-title">
            {event.summary}
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
        {formatDate(event.date, "2")}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
          {formatTime(event.date)}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
        {event.description}
        </Typography>
      </Paper>
    </Modal>
  );
};

export default EventModal;
