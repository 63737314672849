import React, { useState, useEffect } from 'react';
import { Container, Button, Grid, Paper, Box } from '@mui/material';
import CustomAppBar from '../../components/auth/AppBar';
import CustomTabBar from '../../components/auth/TabBar';
import LoginForm from '../../components/auth/LoginForm';
import SignupForm from '../../components/auth/SignupForm';
import ForgotPasswordModal from '../../components/auth/ForgotPasswordModal';
import LockIcon from '@mui/icons-material/Lock';
import { getCurrentUserDetails } from '../../service/firebaseService';
import { useAuth } from '../../context/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { useLoader } from '../../context/LoaderContext';

type User = {
  name: string;
  email: string;
  isAdmin:boolean;
  // Add more properties as needed
};



const Login: React.FC = () => {
  const [value, setValue] = useState<any>(0); // Initialize value as a number

  const [openModal, setOpenModal] = useState(false);
  const {currentUser, setCurrentUser} = useAuth();
  const [userDetail, setUserDetail] = useState<User>({} as User);
  const navigate = useNavigate();
  const {showLoader, hideLoader, isLoading} = useLoader();
  
  const handleCustomTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  
  useEffect(()=> {
    if (localStorage.getItem('currentUser') && currentUser) {
      navigate(`/web/event`);
    } 
  }, [currentUser]);

  return (
    <Box style={{ backgroundColor: '#f5f5f5', minHeight: '100vh', display: 'flex', }}>
      <Container maxWidth="md" style={{ maxWidth: '700px', marginTop: '5rem' }}>
        <CustomAppBar />
        <Paper elevation={3} style={{ padding: '20px', marginTop: '1px', borderRadius: '20px' }}>
          <CustomTabBar value={value} handleChange={handleCustomTabChange} />
          {value == 0 && <LoginForm />}
          {value == 1 && <SignupForm />}
          <Grid container justifyContent="flex-end" alignItems="center" style={{ marginTop: '10px' }} spacing={2}>
            <Grid item>
              <Button startIcon={<LockIcon />} onClick={handleOpenModal} color="primary">Forgot Password?</Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <ForgotPasswordModal open={openModal} handleClose={handleCloseModal} />
    </Box>
  );
};

export default Login;
