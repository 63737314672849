import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface Notification {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'warning' | 'info';
  autoHideDuration?: number | null;
}

interface NotificationContextType {
  notification: Notification;
  showNotification: (message: string, severity: 'success' | 'error' | 'warning' | 'info', autoHideDuration?: number | null) => void;
  hideNotification: () => void;
  forceCloseNotification: () => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);


interface NotificationProviderProps {
    children: ReactNode;
}
  
export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [notification, setNotification] = useState<Notification>({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (notification.open && notification.autoHideDuration !== null) {
      timer = setTimeout(() => {
        hideNotification();
      }, notification.autoHideDuration);
    }
    return () => clearTimeout(timer);
  }, [notification]);

  const showNotification = (message: string, severity: 'success' | 'error' | 'warning' | 'info', autoHideDuration: number | null = null) => {
    setNotification({ open: true, message, severity, autoHideDuration });
  };

  const hideNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const forceCloseNotification = () => {
    // clearTimeout();
    hideNotification();
  };

  return (
    <NotificationContext.Provider value={{ notification, showNotification, hideNotification, forceCloseNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
