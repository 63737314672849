import React from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom'; // Import Outlet from react-router-dom
import DashboardLayout from '../components/dashboard/Layout';
import EventPage from '../pages/dashboard/event';
import AnnouncementPage from '../pages/dashboard/announcement';
import LoginPage from '../pages/auth/login'; // Import LoginPage
import ProtectedRoute from './ProtectedRoute';
import { AuthProvider } from '../context/AuthContext'; // Import your AuthContext (if applicable)
import DocumentPage from '../pages/dashboard/document';
import AdminPage from '../pages/dashboard/admin';
import DataSyncPage from '../pages/dashboard/datasync';

const router = createBrowserRouter([
  {
    path: "/login", // Unauthenticated route (login page)
    element: <LoginPage />
  },
  {
    path: "/", // Unauthenticated route (login page)
    element: <LoginPage />
  },
  {
    path: "/web", // Authenticated route (dashboard layout)
    element: <DashboardLayout />,
    children: [ // Define child routes
    {
      path: "event",
      element: <ProtectedRoute><EventPage /></ProtectedRoute>, // Protected route for announcement page
    },
    {
      path: "announcements",
      element: <ProtectedRoute><AnnouncementPage /></ProtectedRoute>, // Protected route for announcement page
    },
    {
      path: "documents",
      element: <ProtectedRoute><DocumentPage /></ProtectedRoute>, // Protected route for announcement page
    },
    {
      path: "admin",
      element: <ProtectedRoute><AdminPage /></ProtectedRoute>, // Protected route for announcement page
    },
    {
      path: "datasync",
      element: <ProtectedRoute><DataSyncPage /></ProtectedRoute>, // Protected route for announcement page
    },
    {
      path: "*", // Catch-all route for unmatched paths
      element: <p>404: Not Found</p>, // Render a not found message
    },
  ],
  },
]);

export default router;
