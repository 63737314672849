import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './Routes/index'; // Import your router
import { AuthProvider } from './context/AuthContext';
import {NotificationProvider} from './context/NotificationContext';
import { LoaderProvider } from './context/LoaderContext';
import Notification from './components/common/Notification';
import Loader from './components/common/Loader';

function App() {
  return (
    <NotificationProvider>
      <LoaderProvider>
      <AuthProvider>
        <Notification />
        <Loader />
        <RouterProvider router={router} />
      </AuthProvider>
      </LoaderProvider>
    </NotificationProvider>
  );
}

export default App;
