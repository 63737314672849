import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../context/NotificationContext';
import { signUpUser } from '../../service/firebaseService';
import { useLoader } from '../../context/LoaderContext';

const SignupForm = () => {
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const {showLoader, hideLoader, isLoading} = useLoader();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    showLoader();

    try {
      const userCredential = await signUpUser(email, password, fname+' '+lname);
      const user = userCredential?.user;

      if(user != null) {
        hideLoader();
        showNotification('User registered successfully.', 'success', 5000);
        // navigate(`/web/event`); // Navigate to the desired route based on text
      }
      
    } catch (error: any) {
      hideLoader();
      // Handle Firebase authentication errors
      if (error.code === 'auth/email-already-in-use') {
        showNotification('Email is already in use. Please use a different email.', 'warning', 5000);
      } else {
        showNotification('An error occurred while registering. Please try again later.', 'error', 5000);
      }
      console.error('Signup error:', error);
    }
  };

  return (
    <div style={{marginTop:"20px"}}>
      <form onSubmit={handleSubmit}>
      <TextField
          label="Email"
          type="email"
          variant="outlined"
          size="small"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: '10px' }}
          required
        />
        <TextField
          label="First Name"
          variant="outlined"
          size="small"
          fullWidth
          value={fname}
          onChange={(e) => setFName(e.target.value)}
          style={{ marginBottom: '10px' }}
          required
        />
         <TextField
          label="Last Name"
          variant="outlined"
          size="small"
          fullWidth
          value={lname}
          onChange={(e) => setLName(e.target.value)}
          style={{ marginBottom: '10px' }}
          required
        />
      
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          size="small"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginBottom: '10px' }}
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Sign Up
        </Button>
      </form>
    </div>
  );
};

export default SignupForm;
