import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  Paper,
  ListItem,
  ListItemText,
  Drawer,
  Box,
  Card,
  CardContent,
  ListItemIcon,
  Modal,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CampaignIcon from '@mui/icons-material/Campaign';
import SourceIcon from '@mui/icons-material/Source';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SyncIcon from '@mui/icons-material/Sync';
import useLogout from '../../hooks/useLogout';
import { useAuth } from '../../context/AuthContext';
import { getCurrentUserDetails } from '../../service/firebaseService';
import { useLoader } from '../../context/LoaderContext';
import { Navigate, useNavigate } from 'react-router-dom';

const drawerWidth = 240;

type User = {
  name: string;
  email: string;
  isAdmin:boolean;
  // Add more properties as needed
};

const DashboardLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(true);
  const [activeItem, setActiveItem] = useState('');
  const [userDetail, setUserDetail] = useState<User>({} as User);
  const {showLoader, hideLoader, isLoading} = useLoader();

  const {logout} = useLogout();
  const {currentUser} = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const getUser = async() => {
    let user = await getCurrentUserDetails();
    setUserDetail(user)
  }
  
  useEffect(()=> {
    getUser();
    const pathname = location.pathname.replace('/web/', '');
    setActiveItem(pathname);
    if(isMobile) {
      setOpenDrawer(false)
    }
  }, [currentUser, location.pathname]);

  const handleListItemClick = (text:any) => {
    setActiveItem(text);
    navigate(`/web/${text}`);
  };

  const logoutUser = async() => {
    showLoader();
    logout();
    setTimeout(() => {
      hideLoader();
      navigate('/login');
    }, 4000)
    
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar position="fixed" color="transparent" style={{ padding: 0, zIndex: 9999, backgroundColor:"white" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setOpenDrawer(!openDrawer)}
            style={{ marginRight: '20px' }}
          >
            <MenuIcon />
          </IconButton>
          <Typography     variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              fontSize: {
                // xs: '12px', // Small size for extra-small screens
                // sm: '1.25rem', // Default size for small screens and up
              },
            }}>
            Nunawading Assembly
          </Typography>
          <IconButton color="inherit" style={{ marginRight: '10px' }} onClick={logoutUser}>
            <ExitToAppOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{marginTop: "71px", overflow: "scroll", zIndex: 1200, display: 'flex', flexGrow: 1, maxHeight: 'calc(100vh - 64px)' }}>
        {openDrawer  && (
          <Drawer
            anchor="left"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            variant="persistent"
            sx={{
              width: drawerWidth,
              marginTop: '10px',
              overflow: 'hidden',
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                marginTop: '4.3rem',
              },
            }}
          >
            <List sx={{ padding: 0, margin: 0 }}>
            
              <ListItem
                onClick={() => handleListItemClick('event')}
                sx={{
                  backgroundColor: activeItem === 'event' ? '#e7f6ff' : 'inherit',
                  '&:hover': {
                    backgroundColor: '#e7f6ff',
                  },
                }}
              >
                <ListItemIcon>
                <EventNoteIcon />
                </ListItemIcon>
                <ListItemText sx={{
                    cursor: 'pointer'
                  }} primary="Events" />
              </ListItem>
              {/* <ListItem
                onClick={() => handleListItemClick('announcements')}
                sx={{
                  backgroundColor: activeItem === 'announcements' ? '#e7f6ff' : 'inherit',
                  '&:hover': {
                    backgroundColor: '#e7f6ff',
                  },
                }}
              >
                <ListItemIcon>
                <CampaignIcon />
                </ListItemIcon>
                <ListItemText sx={{
                    cursor: 'pointer'
                  }} primary="Announcements" />
              </ListItem> */}
              <ListItem
                onClick={() => handleListItemClick('documents')}
                sx={{
                  backgroundColor: activeItem === 'documents' ? '#e7f6ff' : 'inherit',
                  '&:hover': {
                    backgroundColor: '#e7f6ff',
                  },
                }}
              >
                <ListItemIcon>
                <SourceIcon />
                </ListItemIcon>

                <ListItemText sx={{
                    cursor: 'pointer'
                  }} primary="Document and sermons" />
              </ListItem>
              {userDetail?.isAdmin && <ListItem
                onClick={() => handleListItemClick('admin')}
                sx={{
                  backgroundColor: activeItem === 'admin' ? '#e7f6ff' : 'inherit',
                  '&:hover': {
                    backgroundColor: '#e7f6ff',
                  },
                }}
              >
                <ListItemIcon>
                <SupervisorAccountIcon />
                </ListItemIcon>
                <ListItemText sx={{
                    cursor: 'pointer'
                  }} primary="Admin role" />
              </ListItem>}
              {userDetail?.isAdmin && 
              <ListItem
                onClick={() => handleListItemClick('datasync')}
                sx={{
                  backgroundColor: activeItem === 'datasync' ? '#e7f6ff' : 'inherit',
                  '&:hover': {
                    backgroundColor: '#e7f6ff',
                  },
                }}
              >
                <ListItemIcon>
                <SyncIcon />
                </ListItemIcon>

                <ListItemText sx={{
                    cursor: 'pointer'
                  }} primary="Data sync" />
              </ListItem>
              }
              <div style={{ position: 'absolute', bottom: userDetail?.isAdmin == true ? "-26rem": "-30rem", width: '100%' }}>
                <ListItem>
                  {/* Use an <img> tag for your image icon */}
                  <ListItemIcon>
                  <div style={{ fontSize: "larger",fontWeight: "800",height: "7rem", width: "130px",marginLeft: "2rem" }}>
                    {/* NunaWading Assembly */}
                    <img src="/AppIcon-512.png" style={{ width: '100%', height: '100%' }} alt="Image Icon" />
                  </div>
                  </ListItemIcon>
                  <ListItemText primary="" />
                </ListItem>
              </div>
            </List>
          </Drawer>
        )}
        <Box
          sx={{
            flexGrow: 1,
            overflowX: 'hidden',
            // p: 3,
            maxHeight: 'calc(100vh - 64px)',
            overflowY: 'auto',
          }}
        >
          <Paper elevation={0} style={{ flexGrow: 1 }}>
            <Outlet />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
