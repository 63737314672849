export const formatDate = (dateString: string, type: string): string => {
    const eventDate = new Date(dateString);
  
    // Get day, month, and year
    const day = eventDate.getDate();
    const month = eventDate.toLocaleString('en-US', { month: 'long' });
  
    // Get day name
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayName = days[eventDate.getDay()];
  
    // Combine day, month, and day name
    if (type == "1") {
        return `${day} ${month} ${dayName}`;
    } else {
        return `${dayName} ${day} ${month}`;
    }
};


export const formatTime = (dateString: string): string => {
    const eventDate = new Date(dateString);
    // Get time in format HH:MM AM/PM
    const time = eventDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    return time;
};
