import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import {useNavigate} from 'react-router-dom';
import { useNotification} from './../../context/NotificationContext';
import { signInUser } from '../../service/firebaseService';
import { useAuth } from '../../context/AuthContext';
import { useLoader } from '../../context/LoaderContext';
import { getCurrentUserDetails } from '../../service/firebaseService';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { showNotification, hideNotification } = useNotification();
  const { currentUser, setCurrentUser, signOut } = useAuth();
  const {showLoader, hideLoader, isLoading} = useLoader();


  const handleSubmit = async (event:any) => {
    event.preventDefault();
    showLoader();
    try {
      const userCredential = await signInUser(email, password);
      const user = userCredential?.user;

      if(user != null) {
        let userDetail:any = await getCurrentUserDetails();
        if(userDetail?.isActive == true) {
          setCurrentUser(user)
          showNotification('User logged in successfully. Redirecting to main page.', 'success', 5000);
          setTimeout(()=> {
            hideNotification();
              hideLoader()
              navigate(`/web/event`); // Navigate to the desired route based on text
            }, 2000) 
          } else {
            hideLoader();
            showNotification('Your account is not enabled yet. Please contact to site admin.', 'warning', 5000);
          } 
        }
    } catch (error:any) {
      hideLoader()
      // Handle Firebase authentication errors
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        showNotification('Invalid email or password. Please try again.', 'warning', 5000);
      } else {
        showNotification('An error occurred while logging in. Please try again later.', 'error', 5000);
      }
      console.error('Login error:', error);
    }
  };

  return (
    <div style={{marginTop:"20px"}}>
      <form onSubmit={handleSubmit}>
        <TextField
          type="email"
          label="Email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
          size="small"
          style={{ marginBottom: '10px' }}
          required
        />
        <TextField
          type="password"
          label="Password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
          size="small"
          style={{ marginBottom: '10px' }}
          required
        />
        {error && <p style={{ color: 'red', marginBottom: '10px' }}>{error}</p>}
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Login
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
