import { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, DataSnapshot } from 'firebase/database';
import { getFirebaseConfig } from '../config/firebase.config'; // Import your Firebase configuration
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const useRealtimeDatabase = (path: string) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
// Get Firebase configuration
const firebaseConfig = getFirebaseConfig();

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase services (e.g., authentication, database, etc.)
const auth = getAuth(app);
const database = getDatabase(app);


  useEffect(() => {
    // const database = getDatabase(); // Initialize the Realtime Database without passing config

    const fetchData = async () => {
      try {
        const dbRef = ref(database, path);

        onValue(dbRef, (snapshot: DataSnapshot) => {
          const data = snapshot.val();
          setData(data);
          setLoading(false);
        });
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup function to remove the listener when the component unmounts
    return () => {
      setLoading(false); // Set loading to false to prevent state updates after unmounting
    };
  }, [path]);

  return { data, loading, error };
};

export default useRealtimeDatabase;
