import React, { useState, useEffect } from 'react';
import { Typography, Grid, Pagination, Card, CardContent, Button, Box, IconButton } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import useRealtimeDatabase from '../../hooks/useRealtimeDatabase';
import { useLoader } from '../../context/LoaderContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import the back icon component
import { useAuth } from '../../context/AuthContext';
import { useNotification } from '../../context/NotificationContext';
import { getToken } from '../../service/firebaseService';

interface DocumentItemProps {
  document: Document;
  onClick: () => void;
}

interface Document {
  id: string;
  name: string;
  files: File[];
  webContentLink:string,
  type: 'folder' | 'file';
  children?: Document[];
  parent?: Document | null; // Add parent property
}

interface File {
  createdDate: string;
  embedLink: string;
  fileSize: string;
  iconLink: string;
  id: string;
  mimeType: string;
  originalFilename: string;
  parents: any[]; // You can define a more specific type for parents if needed
  thumbnailLink: string;
  title: string;
  webContentLink: string;
  type: 'file'; // Add the type property
}



interface GetApiURLParams {
  googleApiKey?: string;
  taskId?: string;
  driveFileId?: string;
}

enum GetApiURL {
  kAuthGoogle,
  kGetEvents,
  kGetTasks,
  kGetSubTasks,
  kDriveData,
  kDriveDataDownload,
  kDriveFolderid,
  kSendToken
}

namespace GetApiURLNamespace {
  export function typeURL(url: GetApiURL, urlParams?: GetApiURLParams): string {
      const baseURL = "https://www.googleapis.com/";

      switch (url) {
          case GetApiURL.kAuthGoogle:
              return "https://www.googleapis.com/oauth2/v4/token";
          case GetApiURL.kGetEvents:
              return `${baseURL}calendar/v3/calendars/nunawadingassembly@gmail.com/events?key=${urlParams?.googleApiKey}`;
          case GetApiURL.kGetTasks:
              return `${baseURL}tasks/v1/users/@me/lists?pp=1&key=${urlParams?.googleApiKey}`;
          case GetApiURL.kGetSubTasks:
              return `${baseURL}tasks/v1/lists/${urlParams?.taskId}/tasks?key=${urlParams?.googleApiKey}`;
          case GetApiURL.kDriveData:
              return `${baseURL}drive/v2/files`;
          case GetApiURL.kDriveDataDownload:
              return `${baseURL}drive/v3/files/${urlParams?.driveFileId}?alt=media`;
          case GetApiURL.kDriveFolderid:
              return `${baseURL}drive/v3/files/${urlParams?.driveFileId}`;
          case GetApiURL.kSendToken:
              return "http://app.nunawadinggospelhall.org/church/device_token.php";
          default:
              return "";
      }
  }
}
const DocumentList: React.FC = () => {
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [reloadFile, setReloadFile] = useState(false);
  const [subfolderData, setSubfolderData] = useState<Document[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [documentsPerPage] = useState(12);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<Document[]>([]); // Track breadcrumbs
  const [selectedDocumentStack, setSelectedDocumentStack] = useState<Document[]>([]); // Stack of selected documents

  const { showNotification, hideNotification } = useNotification();
  const {
    currentUser,
    setCurrentUser,
    signOut,
  } = useAuth();
  const parseFiles = (files: any[]): File[] => {
    return files.map((file: any) => ({
      createdDate: file.createdDate,
      embedLink: file.embedLink,
      fileSize: file.fileSize,
      iconLink: file.iconLink,
      id: file.id,
      mimeType: file.mimeType,
      originalFilename: file.originalFilename,
      parents: file.parents,
      thumbnailLink: file.thumbnailLink,
      title: file.title,
      webContentLink: file.webContentLink,
      type: 'file', // Add the type property with the value 'file'
    }));
  };
  
  function handleResponse(response: Response) {
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
  }

  function handleError(error: Error) {
      console.error('There was a problem with the request:', error.message);
  }
  
  const {showLoader, hideLoader, isLoading} = useLoader();
  const { data, loading, error } = useRealtimeDatabase("Drive");

  useEffect(() => {
    if(loading) {
      showLoader();
    } else {
      hideLoader()
    }
    if (data != null) {
      const documentArray: Document[] = Object.values(data).map((document: any) => {
        const type = document.mimeType === 'application/vnd.google-apps.folder' ? 'folder' : 'file';
        let children: Document[] | undefined;
      
        if (type === 'folder') {
          const subfolderData = document.subFolder ? parseSubfolders(JSON.parse(document.subFolder)) : [];
          
          // children = [...subfolderData, ...filesData];
          children = subfolderData
        }
        const filesData = document.files ? parseFiles(JSON.parse(document.files)) : [];
      
        return {
          id: document.id,
          name: document.title,
          files: filesData,
          webContentLink: "" ,
          type,
          children
        };
      });
      
      setDocuments(documentArray);
    }
  }, [data, loading, error, reloadFile]);

  const parseSubfolders = (subfolders: any[]): Document[] => {
    return subfolders.map((subfolder: any) => ({
      id: subfolder.id,
      files: [],
      name: subfolder.title,
      type: 'folder',
      children: subfolder.subFolder ? parseSubfolders(JSON.parse(subfolder.subFolder)) : undefined,
      parent: null,
      webContentLink: '', // Set a default value for webContentLink
    }));
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const   triggerDownload = (fileUrl:any, name:string) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDocumentClick = async (document: Document) => {
  
    if (document.type === 'folder' && document.children) {
      document.children.forEach(child => {
        child.parent = document;
      });
      setBreadcrumbs([...breadcrumbs, document]);
      setDocuments(document.children);
      setSubfolderData([]);
      if(document.children != undefined) {
        setSelectedDocument(document);
        setSelectedDocumentStack([...selectedDocumentStack, document]);
      } else {
        showNotification('No files exists in the folder.', 'warning', 5000);
      }
    } else if (document.type === 'folder') {
      const subfolderId = document.id;
      const subfolderFiles = document?.parent?.files || [];
      const parsedSubfolderFiles = parseFiles(subfolderFiles);
      const filteredSubfolderFiles = parsedSubfolderFiles.filter(file => {
        return file.parents[0].id === subfolderId;
      });
  
      // Convert filtered files to Document objects
      const subfolderDocuments: Document[] = filteredSubfolderFiles.map(file => ({
        id: file.id,
        name: file.title,
        webContentLink: file.webContentLink,
        files: [],
        type: 'file'
      }));
  
      setSubfolderData(subfolderDocuments);

      if(document.children != undefined || filteredSubfolderFiles.length > 0) {
        setSelectedDocument(document);
        setSelectedDocumentStack([...selectedDocumentStack, document]);
      } else {
        showNotification('No files exists in the folder.', 'warning', 5000);
      }

    } else {
      if (document.type === 'file') {
        showNotification('Please wait for some time, while we are opening file.', 'success', 5000);
        const token = await getToken();
        const apiUrl = GetApiURLNamespace.typeURL(GetApiURL.kDriveData, {});
        fetch('https://www.googleapis.com/drive/v3/files/'+document.id+'?alt=media&key=AIzaSyAQsDhRBZNVmEJ0_ok2iW0Ingg1ptD-3Gs', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(response => response.blob())
        .then(blob => {
          const fileUrl = URL.createObjectURL(blob);
          triggerDownload(fileUrl, document.name);
        })
      } else {
        showNotification('No files exists in the folder.', 'warning', 5000);
      }
    
      setOpenModal(true);

    }
  };

  const handleArrowBackClick = () => {
    if (breadcrumbs.length > 0) {
      const previousFolder = breadcrumbs.pop();
      if (previousFolder) {
        setSelectedDocument(previousFolder);
        setSubfolderData([]);
        setBreadcrumbs([...breadcrumbs]);
      }
    } else if (selectedDocumentStack.length > 1) {
      const previousDocument = selectedDocumentStack.pop();
      if (previousDocument) {
        setSelectedDocumentStack(selectedDocumentStack);
        setSelectedDocument(previousDocument.parent || null);
        setSubfolderData([]);
        setBreadcrumbs([]);
      }
      if (data != null) {
        const documentArray: Document[] = Object.values(data).map((document: any) => {
          const type = document.mimeType === 'application/vnd.google-apps.folder' ? 'folder' : 'file';
          let children: Document[] | undefined;
  
          if (type === 'folder') {
            const subfolderData = document.subFolder ? parseSubfolders(JSON.parse(document.subFolder)) : [];
            children = subfolderData;
          }
          const filesData = document.files ? parseFiles(JSON.parse(document.files)) : [];
  
          return {
            id: document.id,
            name: document.title,
            files: filesData,
            webContentLink: "",
            type,
            children
          };
        });

        // Set documents and currentPage together
        setDocuments(documentArray);
        setCurrentPage(1); // Reset currentPage when setting documents
      }
      setSelectedDocument(null);
      setSelectedDocumentStack([]);
      setSubfolderData([]);
      setBreadcrumbs([]);
    } else {
      // If already at root level, set documents to the initial data
      if (data != null) {
        const documentArray: Document[] = Object.values(data).map((document: any) => {
          const type = document.mimeType === 'application/vnd.google-apps.folder' ? 'folder' : 'file';
          let children: Document[] | undefined;
  
          if (type === 'folder') {
            const subfolderData = document.subFolder ? parseSubfolders(JSON.parse(document.subFolder)) : [];
            children = subfolderData;
          }
          const filesData = document.files ? parseFiles(JSON.parse(document.files)) : [];
  
          return {
            id: document.id,
            name: document.title,
            files: filesData,
            webContentLink: "",
            type,
            children
          };
        });
  
        // console.log("Setting documents:", documentArray); // Log the documents being set
  
        // Set documents and currentPage together
        setDocuments(documentArray);
        setCurrentPage(1); // Reset currentPage when setting documents
      }
      setSelectedDocument(null);
      setSelectedDocumentStack([]);
      setSubfolderData([]);
      setBreadcrumbs([]);
    }
  };
  
  
  // Ensure currentPage is set to 1 after state updates
  useEffect(() => {
    setCurrentPage(1);
  }, [documents]);
 
  const renderSubfolderUI = (subfolderData: Document[]) => {
    return (
      <div>
        <Grid container spacing={2}>
          {subfolderData.length === 0 ? (
            <div>No documents found in subfolder.</div>
          ) : (
            subfolderData.map((document: Document) => (
              <Grid item xs={12} sm={6} md={4} key={document.id}>
                <DocumentItem document={document} onClick={() => handleDocumentClick(document)} />
              </Grid>
            ))
          )}
        </Grid>
      </div>
    );
  };

  const DocumentItem: React.FC<DocumentItemProps> = ({ document, onClick }) => {
    const cardStyle: React.CSSProperties = {
      cursor: 'pointer',
      transition: 'transform 0.2s',
      width: 'calc(100% - 2%)'
    };

    const [isHovered, setIsHovered] = useState(false);

    return (
      <div onClick={onClick}>
        <Card
          className={isHovered ? 'hovered' : ''}
          style={cardStyle}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <CardContent>
            {document.type === 'folder' ? <FolderIcon /> : <FileIcon />}
            <Typography variant="body1">{document.name}</Typography>
          </CardContent>
        </Card>
      </div>
    );
  };

  return (
    <div style={{ margin:"5px",border: "1px solid #ccc", borderRadius: "10px" }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5"  gutterBottom sx={{ ml:2, mt:2, mb:5 }} >{selectedDocument  ? selectedDocument.name : 'Documents'}</Typography> 
        <IconButton sx={{bottom: "20px", marginRight:"15px"}} onClick={handleArrowBackClick} color="primary">
          <ArrowBackIcon />
        </IconButton>
      </Box>
      {subfolderData.length === 0 &&
      <>
      <Grid container spacing={2} sx={{ marginLeft:"8px",marginRight:"3px", maxWidth:"98%" }}>
        <Grid container spacing={2}>
          
          {!loading && documents.length === 0 ? (
            <div style={{margin:"29px"}}>No documents found.</div>
          ) : (
            documents
              .slice((currentPage - 1) * documentsPerPage, currentPage * documentsPerPage)
              .map((document: Document) => (
                <Grid item xs={12} sm={6} md={4} key={document.id}>
                  <DocumentItem document={document} onClick={() => handleDocumentClick(document)} />
                </Grid>
              ))
          )}
        </Grid>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
        <Pagination
          count={Math.ceil(documents.length / documentsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
        />
      </div>
      </>}
      {subfolderData.length > 0 && renderSubfolderUI(subfolderData)}
      
    </div>
  );
};

export default DocumentList;
