import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Import your AuthContext

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { currentUser } = useAuth();

  // Redirect to login page if user is not authenticated
  if (!currentUser) {
    return <Navigate to="/login" replace />; // Use replace prop to prevent history accumulation
  }

  // Render the children (protected component) if user is authenticated
  return (
    <>
      <Outlet /> {/* Outlet for rendering nested routes if applicable */}
      {children} {/* Render the wrapped component */}
    </>
  );
};

export default ProtectedRoute;
