
const config = {
  apiKey: "AIzaSyBducQbkwumeIwUR7wHXnyD_gUcmlZSDik",
  authDomain: "assemblyapp.firebaseapp.com",
  databaseURL: "https://assemblyapp.firebaseio.com",
  projectId: "assemblyapp",
  storageBucket: "assemblyapp.appspot.com",
  messagingSenderId: "82247499150",
  appId: "1:82247499150:web:dbb7ef7511300be7d078df"
};

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error('No Firebase configuration object provided.' + '\n' +
    'Add your web app\'s configuration object to firebase-config.ts');
  } else {
    return config;
  }
}    
