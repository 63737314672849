import React, { useState } from 'react';
import { Typography, Grid, Paper, List, Pagination, Card, CardContent, Button, Box, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { syncData } from '../../service/firebaseService';
import { useLoader } from '../../context/LoaderContext';
import { useNotification } from '../../context/NotificationContext';

const DataSyncPage = () => {
    const [open, setOpen] = useState(false);
    const { showNotification } = useNotification();
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = async() => {
        setOpen(false);
        showLoader();
        await syncData();
        hideLoader();
        showNotification('Data synced successfully.', 'success', 5000);
        // Put your logic here for handling confirmation
        
    };
    const {showLoader, hideLoader} = useLoader();

    return (
        <div style={{ margin: "5px", border: "1px solid #ccc", borderRadius: "10px" }}>
            <Typography variant="h5" gutterBottom sx={{ ml: 2, mt: 2 }}>Sync Your Data Here</Typography>
            <Grid container spacing={2} sx={{ marginLeft: "3px", marginRight: "3px", maxWidth: "98%" }}>
                <div style={{ overflowY: 'auto', maxHeight: "600px" }}>
                    <List sx={{ marginTop: "40px",marginLeft:"10px",marginRight:"20px", maxWidth: "98%" }}>
                        {/* Your list content goes here */}
                        <Button variant="contained" onClick={handleOpen}>Sync Data</Button>
                    </List>
                </div>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Sync</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to sync your data?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default DataSyncPage;
