import React from 'react';
import { Tabs, Tab } from '@mui/material';

interface TabBarProps {
  value: number;
  // Update onChange prop type
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const CustomTabBar: React.FC<TabBarProps> = ({ value, handleChange }) => {
  return (
    <Tabs value={value} onChange={(e, newValue) => handleChange(e, newValue)} centered>
      <Tab
        label="Login"
        sx={{
          color: value === 0 ? 'primary.main' : 'inherit',
          borderBottom: value === 0 ? '2px solid' : 'none',
        }}
      />
      <Tab
        label="Signup"
        sx={{
          color: value === 1 ? 'primary.main' : 'inherit',
          borderBottom: value === 1 ? '2px solid' : 'none',
        }}
      />
    </Tabs>
  );
};

export default CustomTabBar;
