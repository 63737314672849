import React, { useState, useEffect } from 'react';
import { Typography, List, ListItem, ListItemText, Divider, Grid, Paper, Modal, Box, IconButton } from '@mui/material';
import {  ListItemSecondaryAction } from '@mui/material';

import useRealtimeDatabase from '../../hooks/useRealtimeDatabase';
import { useLoader } from '../../context/LoaderContext';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface Subtask {
  id: string;
  title: string;
  notes: string;
  due?: string;
  updated?: string;
  selfLink?: string;
}

interface Task {
  id: string;
  title: string;
  notes: string;
  due?: string;
  updated?: string;
  selfLink?: string;
  subtask?: string;
}

const AnnouncementPage: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null); // State to store the selected task
  const { data, loading, error } = useRealtimeDatabase("Tasks");
  const { showLoader, hideLoader } = useLoader();
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  useEffect(() => {
    if (loading) {
      showLoader();
    } else {
      hideLoader();
    }
    if (data !== null) {
      const tasksData: any[] = Array.isArray(data) ? data : Object.values(data);
      setTasks(tasksData);
    }
  }, [data, loading, error]);

  const handleOpenModal = (task: Task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const currentDate = new Date().getTime();
  return (
    <div style={{ margin: "5px", border: "1px solid #ccc", borderRadius: "10px" }}>
      <Typography variant="h4" gutterBottom sx={{ ml: 2, mt: 2 }}>Announcements</Typography>
      <Grid container spacing={2} sx={{ marginLeft: "3px", marginRight: "3px", maxWidth: "98%" }}>
        <div style={{ overflowY: 'auto', maxHeight: "600px", width:"100%" }}>
          <List component={Paper} elevation={3} sx={{ margin: "10px", maxWidth: "98%" }}>
            {!loading && tasks.length == 0 ? (
              // <div>No tasks found.</div>
              <></>
            ) : tasks.map(task => {
              if (!task.subtask) return null; // Skip rendering if subtask is not available
              
              const subtasks: Subtask[] = JSON.parse(task.subtask);
  
              // Check for due date or fallback to updated date
              const hasDueDate = subtasks.some(subtask => subtask.due);
              const dueDateComparator = hasDueDate ? 'due' : 'updated';
              
              // Filter subtasks based on due or updated date
              const filteredSubtasks = subtasks.filter(subtask => {
                const dueDateValue = subtask[dueDateComparator];
                if (dueDateValue === undefined) {
                  return false; // Skip if due date value is undefined
                }
                const currentDate = new Date().getTime();
                const dateToCompare = new Date(dueDateValue).getTime();
                return dateToCompare > currentDate; // Return true if the due date is in the future
              });
              // Skip rendering if no subtasks are available after filtering
              // if (filteredSubtasks.length === 0) return null;
  
              return (
                <div key={task.id} style={{width:"100%"}}>
                <ListItem> {/* Open modal on click */}
                  <ListItemText sx={{ ml: 1 }} primary={task.title} secondary={task.notes} />
                </ListItem>
                <Divider />
                {/* Render subtasks */}
                {filteredSubtasks.length > 0 ? (
                  filteredSubtasks.map(subtask => (
                    <div key={subtask.id}>
                      <ListItem button onClick={() => handleOpenModal(subtask)}>
                        <ListItemText sx={{ ml: 4 }} primary={subtask.title} secondary={subtask.notes} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="details">
                            <KeyboardArrowRightIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </div>
                  ))
                ) : (
                <Box sx={{ width: '100%', display: 'flex' }}>
                    <ListItem sx={{ width: '100%' }}>
                      <ListItemText sx={{ ml: 4 }} primary="No tasks have been found." />
                    </ListItem>
                    <Divider />
                  </Box>
                )}
              </div>
              );
            })}
          </List>
        </div>
      </Grid>
      
      {/* Modal to display task details */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          {selectedTask && (
            <div>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" id="modal-modal-title">
                  {selectedTask.title}
                </Typography>
                <IconButton onClick={handleCloseModal} color="primary">
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography variant="body1">{selectedTask.notes}</Typography>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
  
  
};

export default AnnouncementPage;
