import React from 'react';
import { Snackbar, IconButton } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { useNotification } from './../../context/NotificationContext';

const Notification: React.FC = () => {
  const { notification, hideNotification, forceCloseNotification } = useNotification();

  const handleClose = (event?: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    hideNotification();
  };

  const handleForceClose = (e: any) => {
    forceCloseNotification();
  };

  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={notification.autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Set anchorOrigin to top right
    >
      <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={notification.severity}>
        {notification.message}
        {/* <IconButton size="small" aria-label="close" color="inherit" onClick={handleForceClose}>
          <CloseIcon fontSize="small" />
        </IconButton> */}
      </MuiAlert>
    </Snackbar>
  );
};

export default Notification;
