
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  NextOrObserver,
  User,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,

} from 'firebase/auth';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { getDatabase, ref, get, set, remove } from 'firebase/database';

import { getFirebaseConfig } from './../config/firebase.config';

const app = initializeApp(getFirebaseConfig());
const auth = getAuth(app);
const firestore = getFirestore(app); // Initialize firestore
const database = getDatabase(app);


const refreshToken = "1/oTYrZDNUh1ti7tphuB_IK9xng5AXkZF-HGQGN-2eOUc"
const googleApiKey = "AIzaSyAQsDhRBZNVmEJ0_ok2iW0Ingg1ptD-3Gs"
const googleClientSecret = "QI-ciS52t4lfdS8zc4tnTCz0"
const googleClientId = "82247499150-dpoe1cv0rbhfuvk2dabphsaagln36p8s.apps.googleusercontent.com"

namespace GetApiURLNamespace {
  export function typeURL(url: GetApiURL, urlParams?: GetApiURLParams): string {
    const baseURL = "https://www.googleapis.com/";
    // Define variables for dynamic timeMin and timeMax

    const currentDate = new Date().toISOString();

    // Get the date 20 years from now
    const next20YearsDate = new Date();
    next20YearsDate.setFullYear(next20YearsDate.getFullYear() + 20);
    const next20YearsDateISO = next20YearsDate.toISOString();



    switch (url) {
      case GetApiURL.kAuthGoogle:
        return "https://www.googleapis.com/oauth2/v4/token";
      case GetApiURL.kGetEvents:
        return `${baseURL}calendar/v3/calendars/nunawadingassembly@gmail.com/events?key=${urlParams?.googleApiKey}&timeMin=${currentDate}&timeMax=${next20YearsDateISO}&orderBy=startTime&singleEvents=true`;
      case GetApiURL.kGetTasks:
        return `${baseURL}tasks/v1/users/@me/lists?pp=1&key=${urlParams?.googleApiKey}`;
      case GetApiURL.kGetSubTasks:
        return `${baseURL}tasks/v1/lists/${urlParams?.taskId}/tasks?key=${urlParams?.googleApiKey}`;
      case GetApiURL.kDriveData:
        return `${baseURL}drive/v2/files`;
      case GetApiURL.kDriveDataDownload:
        return `${baseURL}drive/v3/files/${urlParams?.driveFileId}?alt=media`;
      case GetApiURL.kDriveFolderid:
        return `${baseURL}drive/v3/files/${urlParams?.driveFileId}`;
      case GetApiURL.kSendToken:
        return "http://app.nunawadinggospelhall.org/church/device_token.php";
      default:
        return "";
    }
  }
}

interface GetApiURLParams {
  googleApiKey?: string;
  taskId?: string;
  driveFileId?: string;
}

enum GetApiURL {
  kAuthGoogle,
  kGetEvents,
  kGetTasks,
  kGetSubTasks,
  kDriveData,
  kDriveDataDownload,
  kDriveFolderid,
  kSendToken
}


export const signInUser = async (
  email: string,
  password: string
) => {
  if (!email && !password) return;
  return await signInWithEmailAndPassword(auth, email, password)

}

export const userStateListener = (callback: NextOrObserver<User>) => {
  // console.log(auth)
  return onAuthStateChanged(auth, callback)
}

export const SignOutUser = async () => await signOut(auth);


export const signUpUser = async (
  email: string,
  password: string,
  name: string,
) => {
  if (!email || !password || !name) return; // Check if email or password is missing

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    if (auth.currentUser) {
      const database = getDatabase();
      const userRef = ref(database, 'Users/' + auth.currentUser.uid);
      await set(userRef, {
        deviceToken: "",
        deviceType: "web",
        name: name,
        email: email,
        isAdmin: false,
        isActive: false,
        id: auth?.currentUser?.uid,
        password: password
        // Add other user details as needed
      });
    }
    // User successfully signed up
    return userCredential;
  } catch (error) {
    // Handle errors
    console.error('Error signing up:', error);
    throw error;
  }
};

export const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    // Password reset email sent successfully
  } catch (error) {
    // Handle errors
    console.error('Error sending password reset email:', error);
    throw error;
  }
};

// Function to fetch data from a Firestore collection
export const fetchCollectionData = async (collectionName: string) => {
  const collectionRef = collection(firestore, collectionName);
  const querySnapshot = await getDocs(collectionRef);
  const data: any[] = [];

  querySnapshot.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });

  return data;
};

export const getCurrentUserDetails = async () => {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    // console.error("No current user found");
    return; // Handle the case where there's no current user
  }
  const dbRef = ref(database, `Users/${currentUser.uid}`);
  try {
    const snapshot = await get(dbRef); // Use get for Realtime Database

    if (snapshot.exists()) {
      const userData = snapshot.val();
      return userData; // Assuming a single document structure for the user
    } else {
      console.warn("No data found for user:", currentUser.uid);
      return null; // Indicate no data found
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    // Handle errors appropriately (e.g., display an error message)
    return null; // Indicate error
  }
};

interface UserData {
  isActive: boolean;
}

export const updateUserData = async (id: string, userData: UserData): Promise<boolean> => {

  const dbRef = ref(database, `Users/${id}`);
  try {
    const snapshot = await get(dbRef); // Use get for Realtime Database

    if (snapshot.exists()) {
      const userData = snapshot.val();
      userData.isActive = true;
      await set(dbRef, userData); // Update user data in the database
      console.log("User data updated successfully:", userData);
      return true; // Indicate successful update
    } else {
      console.warn("No data found for user:", id);
      return false; // Indicate no data found
    }
  } catch (error) {
    console.error("Error updating user data:", error);
    // Handle errors appropriately (e.g., display an error message)
    return false; // Indicate error
  }
};

export const deleteUser = async (id: string): Promise<boolean> => {

  const dbRef = ref(database, `Users/${id}`);
  try {
    const snapshot = await get(dbRef); // Use get for Realtime Database

    if (snapshot.exists()) {
      await remove(dbRef);
      return true; // Indicate successful update
    } else {
      console.warn("No data found for user:", id);
      return false; // Indicate no data found
    }
  } catch (error) {
    console.error("Error updating user data:", error);
    // Handle errors appropriately (e.g., display an error message)
    return false; // Indicate error
  }
};

export const removeAllChild = async () => {
  try {
    // Remove data from the "Events" node
    const dbRef1 = ref(database, `Events`);
    await remove(dbRef1);

    const dbRef2 = ref(database, `Tasks`);
    await remove(dbRef2);

    const dbRef3 = ref(database, `Drive`);
    await remove(dbRef3);

    // You may want to add additional cleanup steps here if needed

    // Log success message
    console.log("All child data removed successfully.");
  } catch (error) {
    // Handle errors
    console.error("Error removing child data:", error);
    throw error;
  }
}


// Define an async function to synchronize data
export const syncData = async () => {
  try {
    await removeAllChild();
    // Call the API to fetch calendar events
    const calendarEvents = await apiCallenderSync();
    // console.log(calendarEvents, "calenerevents")

    // Store the fetched events in the Firebase database
    await storeEvents(calendarEvents);

    // Call the API to fetch tasks
    // const tasks = await getTaskApi();
    // // console.log(tasks, "tasks")

    // // Store the fetched tasks in the Firebase database
    // if(tasks.length > 0) {
    //   await storeTasks(tasks);
    // }


    // Call the API to sync calendar drive data
    const driveData = await apiCallenderDriveSync();
    // console.log(driveData, "driverData")

    if (driveData.length > 0) {
      // Store the fetched drive data in the Firebase database
      await storeDrive(driveData);
    }

    console.log('Data synchronization completed successfully.');
  } catch (error) {
    console.error('Error synchronizing data:', error);
    throw error;
  }
};

//Function to call the API and fetch calendar events data
const apiCallenderSync = async () => {
  try {
    const token = await getToken();
    // Make API call to fetch calendar events
    const response = await fetch(GetApiURLNamespace.typeURL(GetApiURL.kGetEvents, {
      googleApiKey: googleApiKey,
    }));
    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error('Error fetching calendar events:', error);
    throw error;
  }
};


const getTaskApi = async () => {
  try {
    const token = await getToken();
    // Make API call to fetch tasks
    const response = await fetch(GetApiURLNamespace.typeURL(GetApiURL.kGetTasks, {
      googleApiKey: googleApiKey,
      taskId: "",
      driveFileId: ""
    }), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const tasksData = await response.json();

    // if(tasksData.error.code != 401) {
    // Fetch subtasks for each task
    const tasksWithSubtasks = await Promise.all(tasksData.items.map(async (task: any) => {
      // Make API call to fetch subtasks for the current task
      const subtasksResponse = await fetch(GetApiURLNamespace.typeURL(GetApiURL.kGetSubTasks, {
        googleApiKey: googleApiKey,
        taskId: task.id,
        driveFileId: ""
      }), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const subtasksData = await subtasksResponse.json();
      task.subtasks = JSON.stringify(subtasksData.items); // Assign subtasks to the task
      return task;
    }));
    return tasksWithSubtasks;
  } catch (error) {
    console.error('Error fetching tasks:', error);
    throw error;
  }
};
export const getToken = async () => {
  const response = await fetch("https://www.googleapis.com/oauth2/v4/token", {
    method: 'POST', // Use POST method
    body: JSON.stringify({
      "grant_type": "refresh_token",
      "client_id": "82247499150-dpoe1cv0rbhfuvk2dabphsaagln36p8s.apps.googleusercontent.com",
      "client_secret": "QI-ciS52t4lfdS8zc4tnTCz0",
      "refresh_token": "1/oTYrZDNUh1ti7tphuB_IK9xng5AXkZF-HGQGN-2eOUc"
    }),
    headers: {
      'Content-Type': 'application/json', // Specify content type as JSON
    },
  });

  const data = await response.json();
  return data.access_token;

}
interface DriveItem {
  parents: [{
    selfLink: string,
    id: string,
  }],
  item: {
    mimeType: string;
    parents: {
      isRoot: boolean;
      id: string;
      selfLink: string;
    }[];
  };
  subFolder: string; // Changed to string | null
  files: string | null; // Changed to string | null
}

// Function to call the API and sync calendar drive data
const apiCallenderDriveSync = async (): Promise<DriveItem[]> => {
  try {
    const token = await getToken();
    const response = await fetch("https://www.googleapis.com/drive/v2/files", {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    // Process the response data
    if (data && data.items) {
      const calendarDrive: DriveItem[] = [];
      // Process each item in the response data
      for (const item of data.items) {
        if (item.mimeType == 'application/vnd.google-apps.folder') {
          if (item.parents != undefined && item.parents.length > 0) {
            for (const parent of item.parents) {
              if (parent.isRoot) {
                calendarDrive.push({
                  ...item,
                  subFolder: null, // Initialize subFolder property to null
                  files: null
                });
                break;
              }
            }
          }
        }
      }
      for (const item of data.items) {
        if (item.mimeType == 'application/vnd.google-apps.folder') {
          if (item.parents != undefined && item.parents.length > 0) {
            for (const parent of item.parents) {
              if (parent.isRoot == false) {
                for (let index = 0; index < calendarDrive.length; index++) {
                  const driveItem = calendarDrive[index];
                  const arr = driveItem.parents[0]?.selfLink?.split("/parents");
                  if (arr && arr[0] == parent.parentLink) {
                    if (calendarDrive[index].subFolder === null) {
                      calendarDrive[index].subFolder = JSON.stringify([item]);
                    } else {
                      const subFolderArray = JSON.parse(calendarDrive[index].subFolder || "[]");
                      subFolderArray.push(item);
                      calendarDrive[index].subFolder = JSON.stringify(subFolderArray);
                    }
                  }
                }
              }
            }
          }
        }
      }
      for (const item of data.items) {
        if (item.mimeType != 'application/vnd.google-apps.folder') {
          for (const parent of item.parents) {
            if (parent.isRoot == false) {
              for (let index = 0; index < calendarDrive.length; index++) {
                const driveItem = calendarDrive[index];
                const sub = JSON.parse(driveItem.subFolder);
                for (let index1 = 0; index1 < sub.length; index1++) {
                  const arr = sub[index1].parents[0]?.selfLink?.split("/parents");
                  if (arr && arr[0] == parent.parentLink) {
                    if (calendarDrive[index]?.files == null) {
                      calendarDrive[index].files = JSON.stringify([item]);
                    } else {
                      const subFolderArray = JSON.parse(calendarDrive[index].files || "[]");
                      subFolderArray.push(item);
                      calendarDrive[index].files = JSON.stringify(subFolderArray);
                    }
                  }
                }
              }
            }
          }
        }
      }
      return calendarDrive;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error syncing calendar drive data:', error);
    throw error;
  }
};



// Function to store the fetched events in the Firebase database
const storeEvents = async (eventsData: any[]) => {
  try {
    // Loop through each event data
    for (const eventData of eventsData) {
      // Create an EventsModel instance
      const eventsItems = {
        date: eventData.start.dateTime ?? '',
        updated: eventData.start.dateTime ?? '',
        summary: eventData.summary ?? '',
        status: eventData.status ?? '',
        description: eventData.description ?? '',
        dateEnd: eventData.end?.dateTime ?? '',
      };

      // Get a reference to the specific location in Firebase
      const groceryItemRef = ref(database, `Events/${eventData.id ?? 'id'}`);

      // Set the data at the reference location
      await set(groceryItemRef, eventsItems);
    }

    console.log('Calendar events data stored in Firebase:', eventsData);
  } catch (error) {
    console.error('Error storing calendar events in Firebase:', error);
    throw error;
  }
};

// Function to store the fetched drive data in the Firebase database
const storeDrive = async (driveData: any) => {
  try {
    // Loop through each drive data
    for (const driveDatum of driveData) {
      // Create a DriveModel instance

      // let eventsItems = DriveModel.init(mimeType: i.mimeType.map { $0.rawValue } ?? "")
      // console.log(driveData.mimeType)


      const driveItems = {
        title: driveDatum.title ?? '',
        thumbnailLink: driveDatum.thumbnailLink ?? '',
        webContentLink: driveDatum.webContentLink ?? '',
        originalFilename: driveDatum.originalFilename ?? '',
        fileSize: driveDatum.fileSize ?? '',
        createdDate: driveDatum.createdDate ?? '',
        iconLink: driveDatum.iconLink ?? '',
        mimeType: driveDatum.mimeType,
        embedLink: driveDatum.embedLink ?? '',
        id: driveDatum.id ?? '',
        subFolder: driveDatum.subFolder ?? '',
        files: driveDatum.files ?? ''
      };

      // Get a reference to the specific location in Firebase
      const groceryItemRef = ref(database, `Drive/${driveDatum.id ?? 'id'}`);

      // Set the data at the reference location
      await set(groceryItemRef, driveItems);
    }

    console.log('Drive data stored in Firebase:', driveData);
  } catch (error) {
    console.error('Error storing drive data in Firebase:', error);
    throw error;
  }
};


// Function to store the fetched tasks in the Firebase database
const storeTasks = async (tasksData: any[]) => {
  try {
    // Loop through each task data
    for (const taskData of tasksData) {
      // Create a TaskModel instance
      const taskItems = {
        id: taskData.id ?? '',
        title: taskData.title ?? '',
        selfLink: taskData.selfLink ?? '',
        subTask: taskData.subtasks ?? ''
      };

      // Get a reference to the specific location in Firebase
      const groceryItemRef = ref(database, `Tasks/${taskData.id ?? 'id'}`);

      // Set the data at the reference location
      await set(groceryItemRef, taskItems);
    }
  } catch (error) {
    console.error('Error storing tasks in Firebase:', error);
    throw error;
  }
};