import React, { useState } from 'react';
import { Modal, Typography, TextField, Button, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { resetPassword } from '../../service/firebaseService';
import { useNotification } from '../../context/NotificationContext';

interface ForgotPasswordModalProps {
  open: boolean;
  handleClose: () => void;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({ open, handleClose }) => {
  const [email, setEmail] = useState<string>('');

  const { showNotification } = useNotification();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await resetPassword(email);
      // Password reset email sent successfully
      showNotification('Password reset email has been sent to your email address.', 'success', 5000);
      setEmail("")
    } catch (error:any) {
      // Handle errors
      if (error.code === 'auth/user-not-found') {
        showNotification('Email not found. Please check your email or sign up.', 'warning', 5000);
      } else {
        showNotification('An error occurred. Please try again later.', 'error', 5000);
      }
      console.error('Error:', error);
    }
  
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ backgroundColor: 'white', p: 2, borderRadius: '10px', width: '50vw', maxWidth: '500px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" id="modal-modal-title">
            Forgot Password
          </Typography>
          <IconButton onClick={handleClose} color="primary">
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="body1" id="modal-modal-description" mb={2}>
          Enter your email address to reset your password.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" color="primary">
            Reset Password
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default ForgotPasswordModal;
