import React from 'react';

const CustomAppBar = () => {
  return (
    <div>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <img src="AppIcon-512@3.png" alt="Logo" style={{ width: '120px', height: '100px' }} />
      </div>
      <div style={{ textAlign: 'center', fontSize:"1.5rem" }}>
        <p>Welcome to Nunawading Assembly</p>
      </div>
    </div>
  );
};

export default CustomAppBar;
