import { User } from "firebase/auth";
import { createContext, useState, useEffect, ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SignOutUser, getCurrentUserDetails, userStateListener } from "./../service/firebaseService";

interface Props {
  children?: ReactNode;
}
interface FirebaseUser {
  uid: string;
  email: string;
  emailVerified: boolean;
  isAnonymous: boolean;
  providerData: ProviderData[];
  stsTokenManager: {
    refreshToken: string;
    accessToken: string;
    expirationTime: number;
  };
  createdAt: string; // Assuming this is a timestamp
  lastLoginAt: string; // Assuming this is a timestamp
  apiKey: string;
  appName: string;
}
interface ProviderData {
  providerId: string;
  uid: string;
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
}

export const AuthContext = createContext({
  currentUser: {} as User | null,
  setCurrentUser: (_user: User | null) => {},
  signOut: () => {},
});

export const AuthProvider = ({ children }: Props) => {
  const [currentUser, setCurrentUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem('currentUser');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  // const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async (user:any) => {
      // console.log(user);
      if (user) {
        try {
          let userDetail = await getCurrentUserDetails();
          // console.log(userDetail)
          if (userDetail.isActive) {
            setCurrentUser(user);
            localStorage.setItem('currentUser', JSON.stringify(user));
          } else {
            setCurrentUser(null);
            localStorage.removeItem('currentUser');
          }
        } catch (error) {
          console.error('Error fetching user details:', error);
          setCurrentUser(null);
          localStorage.removeItem('currentUser');
        }
      } 
      // else {
      //   setCurrentUser(null);
      //   localStorage.removeItem('currentUser');
      // }
    };
  
    const unsubscribe = userStateListener((user) => {
      fetchData(user);
    });
  
    return unsubscribe;
  }, []);
  

  const signOut = () => {
    SignOutUser();
    setCurrentUser(null);
    localStorage.removeItem('currentUser');
  };

  const value = {
    currentUser,
    setCurrentUser,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
