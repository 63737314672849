import { useState, useEffect } from 'react';
import {useAuth} from '../context/AuthContext';

const useLogout = () => {
    const {
        currentUser,
        setCurrentUser,
        signOut,
      } = useAuth();

    const logout = async() => {
        await signOut();
        localStorage.clear();
    }

  return { logout };
};

export default useLogout;
